/*
 * Copyright (C) 2019 Zippie Ltd.
 * 
 * Commercial License Usage
 * 
 * Licensees holding valid commercial Zippie licenses may use this file in
 * accordance with the terms contained in written agreement between you and
 * Zippie Ltd.
 * 
 * GNU Affero General Public License Usage
 * 
 * Alternatively, the JavaScript code in this page is free software: you can 
 * redistribute it and/or modify it under the terms of the GNU Affero General Public
 * License (GNU AGPL) as published by the Free Software Foundation, either
 * version 3 of the License, or (at your option) any later version.  The code
 * is distributed WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU AGPL for
 * more details.
 * 
 * As additional permission under GNU AGPL version 3 section 7, you may
 * distribute non-source (e.g., minimized or compacted) forms of that code
 * without the copy of the GNU GPL normally required by section 4, provided
 * you include this license notice and a URL through which recipients can
 * access the Corresponding Source.
 * 
 * As a special exception to the AGPL, any HTML file which merely makes
 * function calls to this code, and for that purpose includes it by reference
 * shall be deemed a separate work for copyright law purposes.  In addition,
 * the copyright holders of this code give you permission to combine this
 * code with free software libraries that are released under the GNU LGPL.
 * You may copy and distribute such a system following the terms of the GNU
 * AGPL for this code and the LGPL for the libraries.  If you modify this
 * code, you may extend this exception to your version of the code, but you
 * are not obligated to do so.  If you do not wish to do so, delete this
 * exception statement from your version.
 *  
 * This license applies to this entire compilation.
 */

import Crypto from 'crypto'

// XXX - Move to klaatu-utils or vault-api or something

export const WM_EVENT_PRE_BOOTING = "preBooting"
export const WM_EVENT_BOOTING = "booting"
export const WM_EVENT_BOOTED  = "booted"
export const WM_EVENT_RUNNING = "running"

/**
 * 
 * @param {*} data 
 * @param {*} key 
 * @param {*} iv 
 */
export function encrypt (data, key, iv) {
  const cipher = Crypto.createCipheriv('aes-128-cbc', key, iv)

  return new Promise(function (resolve) {
    let result = new Buffer(0)

    cipher.on('readable', function () {
      const data = cipher.read()
      if (data) result = Buffer.concat([result, data])
    })

    cipher.on('end', function () {
      resolve(result)
    })

    cipher.write(data)
    cipher.end()
  })
}

/**
 * 
 * @param {*} ciphertext 
 * @param {*} key 
 * @param {*} iv 
 */
export function decrypt (ciphertext, key, iv) {
  const cipher = Crypto.createDecipheriv('aes-128-cbc', key, iv)

  return new Promise(function (resolve) {
    let result = new Buffer(0)

    cipher.on('readable', function () {
      const data = cipher.read()
      if (data) Buffer.concat([result, data])
    })

    cipher.on('end', function () {
      resolve(result)
    })

    cipher.write(ciphertext)
    cipher.end()
  })
}

/**
 * 
 * @param {*} uri 
 */
export function hashToParams (uri) {
  let parser = document.createElement('a')
  parser.href = uri

  let hparts = parser.hash.slice(1).split('?')
  let params = {}

  let query = hparts[1]
  if (query !== undefined) {
    let qparts = query.split(';')
    for (let i = 0; i < qparts.length; i++) {
      let kv = qparts[i].split('=')
      params[kv[0]] = kv[1] ? (decodeURIComponent(kv[1])) : true
    }
  }

  return params
}

/**
 * 
 */
export function detectDeviceName () {
  let deviceName = null

  if (navigator.userAgent.includes('Firefox')) {
    deviceName = 'Firefox'
  } else if (navigator.userAgent.includes('Opera') || navigator.userAgent.includes('OPR') || navigator.userAgent.includes('OPT')) {
    deviceName = 'Opera'
  } else if (navigator.userAgent.includes('Chrome')) {
    deviceName = 'Chrome'
  } else if (navigator.userAgent.includes('Safari')) {
    deviceName = 'Safari'
  }

  // If we've not detected a supported browser, bail out.
  if (deviceName === null) return null

  if (navigator.userAgent.includes('iPhone')) {
    deviceName += ' iPhone'
  } else if (navigator.userAgent.includes('iPad')) {
    deviceName += ' iPad'
  } else if (navigator.userAgent.includes('iPod')) {
    deviceName += ' iPod'
  } else if (navigator.platform.toUpperCase().includes('MAC')) {
    deviceName += ' Mac'
  } else if (navigator.userAgent.includes('Mobi')) {
    deviceName += ' Mobile'
  } else if (navigator.userAgent.includes('Tablet')) {
    deviceName += ' Tablet'
  } else {
    deviceName += ' Desktop'
  }

  return deviceName
}

export const getKlaatuEnv = () => {
  let runtime_mode = 'release'

  if (window.location.host.indexOf('localhost') !== -1) {
    runtime_mode = 'development'
  } else if (window.location.host.indexOf('ngrok') !== -1) {
    runtime_mode = 'development'
  } else if (window.location.host.indexOf('dev.zippie.org') !== -1) {
    runtime_mode = 'development'
  } else if (window.location.host.indexOf('testing.zippie.org') !== -1) {
    runtime_mode = 'testing'
  } else if (window.location.host.indexOf('dev.zippie.com') !== -1) {
    runtime_mode = 'development'
  } else if (window.location.host.indexOf('testing.zippie.com') !== -1) {
    runtime_mode = 'testing'
  }

  return runtime_mode;
}