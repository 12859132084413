/*
 * Copyright (C) 2019 Zippie Ltd.
 * 
 * Commercial License Usage
 * 
 * Licensees holding valid commercial Zippie licenses may use this file in
 * accordance with the terms contained in written agreement between you and
 * Zippie Ltd.
 * 
 * GNU Affero General Public License Usage
 * 
 * Alternatively, the JavaScript code in this page is free software: you can 
 * redistribute it and/or modify it under the terms of the GNU Affero General Public
 * License (GNU AGPL) as published by the Free Software Foundation, either
 * version 3 of the License, or (at your option) any later version.  The code
 * is distributed WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU AGPL for
 * more details.
 * 
 * As additional permission under GNU AGPL version 3 section 7, you may
 * distribute non-source (e.g., minimized or compacted) forms of that code
 * without the copy of the GNU GPL normally required by section 4, provided
 * you include this license notice and a URL through which recipients can
 * access the Corresponding Source.
 * 
 * As a special exception to the AGPL, any HTML file which merely makes
 * function calls to this code, and for that purpose includes it by reference
 * shall be deemed a separate work for copyright law purposes.  In addition,
 * the copyright holders of this code give you permission to combine this
 * code with free software libraries that are released under the GNU LGPL.
 * You may copy and distribute such a system following the terms of the GNU
 * AGPL for this code and the LGPL for the libraries.  If you modify this
 * code, you may extend this exception to your version of the code, but you
 * are not obligated to do so.  If you do not wish to do so, delete this
 * exception statement from your version.
 *  
 * This license applies to this entire compilation.
 */
import {getKlaatuEnv} from './utils';
import {namehash} from '@ethersproject/hash'
import * as contentHash from 'content-hash'

import fakeIndexedDB from 'fake-indexeddb/build/fakeIndexedDB'
import FDBCursor from 'fake-indexeddb/build/FDBCursor'
import FDBCursorWithValue from 'fake-indexeddb/build/FDBCursorWithValue'
import FDBDatabase from 'fake-indexeddb/build/FDBDatabase'
import FDBFactory from 'fake-indexeddb/build/FDBFactory'
import FDBIndex from 'fake-indexeddb/build/FDBIndex'
import FDBKeyRange from 'fake-indexeddb/build/FDBKeyRange'
import FDBObjectStore from 'fake-indexeddb/build/FDBObjectStore'
import FDBOpenDBRequest from 'fake-indexeddb/build/FDBOpenDBRequest'
import FDBRequest from 'fake-indexeddb/build/FDBRequest'
import FDBTransaction from 'fake-indexeddb/build/FDBTransaction'
import FDBVersionChangeEvent from 'fake-indexeddb/build/FDBVersionChangeEvent'


/**
 * Detect Runtime Exection Mode
 */
window.runtime_mode = getKlaatuEnv();
let ens_cache = {}
let ens_ongoing = {}

window.lookupENSPath = async function(ensPath) {
    if (ens_cache[ensPath] && (Math.floor(Date.now() / 1000) - ens_cache[ensPath].timestamp < 43200)) {
       return ens_cache[ensPath].resolved
    } else if (!ens_ongoing[ensPath]) {
       ens_ongoing[ensPath] = []
    } else {
       await (new Promise((resolve, reject) => {
          ens_ongoing[ensPath].push(function () {
             resolve()
          })
       }))
       return ens_cache[ensPath].resolved
    }
    
    //  /ens/NETWORK/NAME(?/path)
    let sp = ensPath.split('/')

    const node_url = {
      'development': 'https://zippienet-eth.dev.zippie.com/rpc',
      'testing': 'https://zippienet-eth.testing.zippie.com/rpc',
      'release': 'https://zippienet-eth.zippie.com/rpc'
    }
 
    let nhash = namehash(sp[3])

    /* let registry = provider.network.ensAddress
    let registryContract = new ethers.Contract(registry, [
        "function resolver(bytes32) view returns (address)"
    ], provider);

    let resolver = await registryContract.resolver(hash)
    if (resolver === ethers.constants.AddressZero) {
        throw new Error('missing resolver')
    } */
    /* XXX hack */
    const contract = '0x9cbdbe78b91416cf11a6ad4fbe9a63a6e7955711' // PublicResolverAddress
    const method = '0xbc1c58d1' // web3.utils.keccak256("contenthash(bytes32)").slice(0,10)
    const data = `${method}${nhash.slice(2)}`

    const url = node_url[runtime_mode]
    try { 
      const response = await 
        (await fetch(url, 
          { method: 'POST', mode: 'cors', 
            headers: { 'Content-Type': 'application/json' }, 
            body: JSON.stringify({
              jsonrpc: '2.0',
              method: 'eth_call',
              params: [{ to: contract, data: data }, 'latest'],
              id: 1,
            })
          })).json()

      const chash = `0x${response.result.slice(2).slice(64).slice(64).slice(0, 76)}`
    
      if (contentHash.getCodec(chash) !== 'ipfs-ns') {
          throw new Error('incompatible content hash')
      }
      let cid = contentHash.decode(chash)
      if (sp.slice(4).length > 0) {
          return '/ipfs/' + cid + '/' + sp.slice(4).join('/')
      }
      ens_cache[ensPath] = { resolved: '/ipfs/' + cid, timestamp: Math.floor(Date.now() / 1000) }
      for (var i = 0; i < ens_ongoing[ensPath].length; i++) {
        ens_ongoing[ensPath][i]()
      }
      delete ens_ongoing[ensPath]
      return '/ipfs/' + cid
    } catch (err) {
      for (var i = 0; i < ens_ongoing[ensPath].length; i++) {
        ens_ongoing[ensPath][i]()
      }
      throw err
    }
}


const analyticsTracker = ({ metricName, data, duration }) => {
  const savedAnalyticsData = window.analyticsData || {}
  let analyticsData
  if (metricName === 'resourceTiming') {
    const resourceTiming = savedAnalyticsData.resourceTiming || []
    analyticsData = { ...savedAnalyticsData, [metricName]: [...resourceTiming, data.toJSON()] }
  } else {
    analyticsData = { ...savedAnalyticsData, [metricName]: { ...data, duration } }
  }
  window.analyticsData = analyticsData
  return
}






/* window.perfume = new Perfume({
  firstContentfulPaint: true,
  firstPaint: true,
  firstInputDelay: true,
  dataConsumption: true,
  largestContentfulPaint: true,
  navigationTiming: true,
  networkInformation: true,
  resourceTiming: true,
  maxMeasureTime: 120000,
  analyticsTracker,
  logging: false
})

const getPerformanceData = () => {
  if(window.analyticsData) {
    sendEvent('perfume_perfdata', {...window.analyticsData})
  }
}
*/

//wait for 120 seconds 
//setTimeout(getPerformanceData, 120000)

let brotli_cache = {}

window.brotli_decompress = async function (content) {
   if (!window.decompress) { 
     window.decompress = (await System.import(/* webpackChunkName: '01brotli', webpackPreload: 80 */ '@zippie/brotli/dec/decode')).BrotliDecompressBuffer
   }
   let digest = await crypto.subtle.digest('SHA-256', content.buffer)
   let hash = (Buffer.from(digest)).toString('hex')
   if (brotli_cache[hash]) {
      console.log('brotli cache hit: ' + hash)
      return Buffer.from(brotli_cache[hash].buffer.slice(0))
   } else {
      brotli_cache[hash] = Buffer.from(decompress(content))
      return Buffer.from(brotli_cache[hash].buffer.slice(0))
   }
}


var currentManifest = {
  "name": "Zippie",
  "short_name": "Zippie",
  "start_url": "./m/",
  "scope": "./m/",
  "display": "standalone",
  "background_color": "#ffffff",
  "theme_color": "#ffffff",
  "description": "Zippie",
  "icons" :[{
    "src": location.origin + "/android-chrome-192x192.png",
    "sizes": "192x192",
    "type": "image/png"
  },
  {
    "src": location.origin + "/android-chrome-512x512.png",
    "sizes": "512x512",
    "type": "image/png"
  }]
}

var isiPhoneOrOlderIpad = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
var isIpad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
if (!(isiPhoneOrOlderIpad || isIpad)) {
  window.zippie_pwa = true
  if (window.location.hash.startsWith('#a2hs=')) {
    var spl = window.location.hash.slice('#a2hs='.length)
    console.log('getting ' + spl)
    document.getElementById('manifest-placeholder').href = 'data:application/manifest+json;base64,' + localStorage.getItem('a2hs-' + spl)
  } else { 
    // this is a trick to always have possibility of adding to homescreen
    var cur = Math.floor(Date.now() / 100)
    currentManifest.scope = window.location.origin + '/m-' + cur + '/'
    currentManifest.start_url = window.location.origin + '/m-' + cur + '/'
    document.getElementById('manifest-placeholder').href = 'data:application/manifest+json;base64,' + btoa(JSON.stringify(currentManifest))
  }

  window.boot0_a2hs = document.getElementById('manifest-placeholder').href
  window.addEventListener('beforeinstallprompt', (e) => {
    // new design
    if (window.location.hash.startsWith('#a2hs=')) {
      console.log('boot0: got beforeinstallprompt but returning')
      window.deferredPrompt = e;
      return
    }
    // Stash the event so it can be triggered later.
    console.log('boot0: got beforeinstallprompt, masking until later use')
    e.preventDefault()
    window.deferredPrompt = e;
    if (window.deferredPromptHandler) {
       console.log('boot0: passing on to deferredPromptHandler')
       window.deferredPromptHandler()
    }
  })
  
  window.addEventListener('appinstalled', (evt) => {
    console.log('a2hs installed');
  })
} else {
  window.zippie_pwa = false
}  

var bootstrap = []
if (location.hash.startsWith('#dev-ask')) {
   bootstrap = JSON.parse(prompt('bootstrap?', JSON.stringify(bootstrap)))
}

if (location.hash.startsWith('#wipe') && confirm('Do you really want to wipe Zippie state? This may cause data or money loss.')) {
   location.hash = '#app'
   localStorage.clear()
   window.location.reload()
}

window.ipfs_fetch = async function(cid, brotli = false) {
   const chunks = []
   for await (const chunk of window.ipfs.cat(cid)) {
     chunks.push(chunk)
   }
  const contents = Buffer.concat(chunks)
  let decompressed = contents
  if (brotli) {
    try {
      decompressed = Buffer.from(await window.brotli_decompress(decompressed))
    } catch (err) {
      console.log(err)
    }
  }
  return decompressed
}

if (location.hash.startsWith('#pay')) {
  let prelookups = ENS_PAY_PRELOOKUPS[runtime_mode]
  for (var i = 0; i < prelookups.length; i++) {
     lookupENSPath(prelookups[i])
  }
} else if (location.hash.startsWith('#dev-content=')) {
  if (!location.hash.startsWith('#dev-content=http')) {
    lookupENSPath(location.hash.slice('#dev-content='.length)).then((cid) => {
      var hint = document.createElement("link")
      hint.rel = "preload"
      hint.href = 'https://global-ipfs-fp.dev.zippie.org/api/v0/block/get/' + cid.slice('/ipfs/'.length)
      hint.as = 'fetch'
      hint.setAttribute('crossorigin', 'anonymous');
      document.head.appendChild(hint)
    })
  }
  let prelookups = ENS_PAY_PRELOOKUPS[runtime_mode]
  for (var i = 0; i < prelookups.length; i++) {
     lookupENSPath(prelookups[i])
  }
} else {
  let prelookups = ENS_PAY_PRELOOKUPS[runtime_mode]
  for (var i = 0; i < prelookups.length; i++) {
     lookupENSPath(prelookups[i])
  }
}

if (window.do_preloads) {
   window.do_preloads()
}

async function initboot0() {
  if (window.parent !== window.self) {
    window.noLocalStorageAccess = false
    if (document.hasStorageAccess && !(await document.hasStorageAccess())) {
      window.noLocalStorageAccess = true
    } else {
      try {
        if (window.localStorage == null) {
          throw new Error('No window.localStorage')
        }
        localStorage.setItem('test-access', '1')
        if (localStorage.getItem('test-access') === '1') {
          window.noLocalStorageAccess = false
        } else {
          throw new Error('Error saving to localStorage')
        }
        if (window.indexedDB.databases) 
           await window.indexedDB.databases()
      } catch (err) {
         console.log(err)
         window.noLocalStorageAccess = true
        }
    }
    
    // don't allow local storage access

    if (window.noLocalStorageAccess) {
      delete window['localStorage']
      var globalVar =
        typeof window !== "undefined"
          ? window
          : typeof WorkerGlobalScope !== "undefined"
            ? self
            : typeof global !== "undefined"
              ? global
              : Function("return this;")();
  
      delete window.indexedDB
      delete window.IDBCursor
      delete window.IDBCursorWithValue
      delete window.IDBDatabase
      delete window.IDBFactory
      delete window.IDBIndex
      delete window.IDBKeyRange
      delete window.IDBObjectStore
      delete window.IDBOpenDBRequest
      delete window.IDBRequest
      delete window.IDBTransaction
      delete window.IDBVersionChangeEvent
  
      globalVar.indexedDB = fakeIndexedDB;
      globalVar.IDBCursor = FDBCursor;
      globalVar.IDBCursorWithValue = FDBCursorWithValue;
      globalVar.IDBDatabase = FDBDatabase;
      globalVar.IDBFactory = FDBFactory;
      globalVar.IDBIndex = FDBIndex;
      globalVar.IDBKeyRange = FDBKeyRange;
      globalVar.IDBObjectStore = FDBObjectStore;
      globalVar.IDBOpenDBRequest = FDBOpenDBRequest;
      globalVar.IDBRequest = FDBRequest;
      globalVar.IDBTransaction = FDBTransaction;
      globalVar.IDBVersionChangeEvent = FDBVersionChangeEvent;
  
      window.localStorage = {
        setItem: function (key, value) {
          console.error('[localStorage/setItem] ' + key + ' = ' + value)
          if (window.temporaryLocalStorage) {
            window.temporaryLocalStorage[key] = value
          } else {
            throw new Error('No local storage in embed')
          }
        },
        getItem: function (key) {
          if (window.temporaryLocalStorage) {
            return window.temporaryLocalStorage[key]
          } else {
            console.error('[localStorage/getItem before loading] ' + key)
            return undefined
          }
        },
        removeItem: function (key) {
          if (window.temporaryLocalStorage) {
            window.temporaryLocalStorage[key] = undefined
            return
          } else {
            console.error('[localStorage/removeItem before loading] ' + key)
            return
          }
        },
        clear: function () {
          throw new Error('No local storage in embed')
        }
      }
    } else {
      window.temporaryLocalStorage = {}
    }
    window.klaatu_embedded_scenario = true
  }
  if (!window.klaatu_opener_scenario && !window.klaatu_embedded_scenario && window.location.hash.length === 0 && !window.location.href.includes('8443')) {
    window.location = 'https://www.zippie.com'
  }
}

initboot0().then(() => {
  System.import(/* webpackChunkName: '00mini-ipfs', webpackPreload: 100 */ './mini-ipfs.js').then(async (module) => {
    console.log('mini ipfs imported')
    window.ipfs = new module.MiniIPFS()
    console.log('mini ipfs constructed')
    await window.ipfs.start()
    console.log('mini ipfs started')
    await System.import(/* webpackChunkName: '02klaatu', webpackPreload: 90 */ './boot.js')
    console.log('boot finished')
    if (window.do_preloads_home) {
      window.do_preloads_home()
    }
  })
}).catch((err) => {
  console.log('*** FAILURE TO BOOT KLAATU ***')
  console.log(err)
})
